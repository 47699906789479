<template>
  <div class="search">
    <div class="container">
      <app-card>
        <h1 class="title search__title">Створення паціента</h1>
        <client-form />
      </app-card>
    </div>
  </div>
</template>
<script>
import ClientForm from "./NewClientForm.vue";
export default {
  components: { ClientForm },
};
</script>

<style scoped lang="scss">
.search {
  margin-top: 17px;
  margin-bottom: 80px;
  &__title {
    margin-bottom: 41px;
    text-align: center;
    @media (max-width: 767px) {
      font-size: 26px;
      margin-bottom: 35px;
    }
    @media (max-width: 374px) {
      font-size: 22px;
      margin-bottom: 24px;
    }
  }
}
</style>