<template>
  <div class="client__wrap">
    <form class="client__form" @submit.prevent="createNewPatient">
      <div class="client__form-wrap">
        <div
          class="client__form-item"
          v-for="item in calcActualInfo()"
          :key="item.id"
        >
          <label :for="item.id" class="client__form-label">{{
            item.label
          }}</label>
          <base-input
            :id="item.id"
            v-if="item.elType === 'input'"
            :item="item"
          />
          <date-picker
            v-else-if="item.elType === 'date'"
            v-model="item.model"
            valueType="format"
            style="width: 100%"
            :placeholder="item.placeholder"
          />
          <v-select
            v-else-if="item.elType === 'select'"
            :placeholder="item.placeholder"
            v-model="item.model"
            :options="item.options"
          />
        </div>
      </div>
      <div class="client__form-download">
        <label class="client__form-label"
          >Завантажити фото угоди пацієнта</label
        >
        <file-upload
          ref="upload"
          class="client__form-upload"
          :drop="true"
          accept="image/png,image/gif,image/jpeg,image/webp"
          v-model="file"
        >
          <div class="client__form-icon">
            <img src="@/assets/img/icons/download.svg" alt="" />
          </div>
          <span class="client__form-text"
            >Натисніть або перетягніть файл у цю область, щоб завантажити</span
          >
          <span class="client__form-about"
            >Максимальний розмір файлу 10 МБ</span
          >
        </file-upload>
        <span class="client__form-attached" v-if="fileAttached"
          >Файл прикріплено</span
        >
      </div>
      <error :error="error" />
      <base-btn>Створити</base-btn>
    </form>
  </div>
</template>
<script>
import { mapActions, mapMutations } from "vuex";
export default {
  data() {
    return {
      error: null,
      fileAttached: null,
      file: [],
      formInit: [
        {
          id: "first_name",
          label: "Прізвище",
          elType: "input",
          placeholder: "",
          model: "",
          visible: true,
        },
        {
          id: "last_name",
          label: "Ім'я",
          elType: "input",
          placeholder: "",
          model: "",
          visible: true,
        },
        {
          id: "middle_name",
          label: "По батькові",
          elType: "input",
          placeholder: "",
          model: "",
          visible: true,
        },
        {
          id: "birthdate",
          label: "Дата народження",
          elType: "date",
          placeholder: "Оберіть дату",
          model: "",
          visible: true,
        },
        {
          id: "sex",
          label: "Стать",
          elType: "select",
          placeholder: "Пошук...",
          model: "",
          options: [
            { label: "Чоловіча", id: 0 },
            { label: "Жіноча", id: 1 },
          ],
          visible: true,
        },
        {
          id: "phone_number",
          label: "Номер телефону",
          elType: "input",
          placeholder: "",
          model: "",
          type: "number",
          visible: true,
        },
        {
          id: "email",
          label: "Електронна пошта",
          elType: "input",
          placeholder: "",
          model: "",
          visible: true,
        },
        {
          id: "growth",
          label: "Зріст",
          elType: "input",
          placeholder: "",
          model: "",
          type: "number",
          visible: true,
        },
        {
          id: "weight",
          label: "Вага",
          elType: "input",
          placeholder: "",
          model: "",
          type: "number",
          visible: true,
        },
        {
          id: "glucose",
          label: "Глюкоза",
          elType: "input",
          placeholder: "",
          model: "",
          type: "number",
          visible: true,
        },
        {
          id: "lifestyle",
          label: "Спосіб життя",
          elType: "select",
          placeholder: "Пошук...",
          model: "",
          options: [
            { label: "Низький", id: "low" },
            { label: "Середній", id: "medium" },
            { label: "Активний", id: "high" },
            { label: "Лежачий", id: "recumbent" },
          ],
          visible: true,
        },
        {
          id: "smoke",
          label: "Куріння",
          elType: "select",
          placeholder: "Пошук..",
          model: "",
          options: [
            { label: "Сигарети", id: "cigarettes" },
            { label: "Кальян", id: "hookah" },
            { label: "Вейп тощо", id: "vape_other" },
            { label: "Не палю", id: "no_smoke" },
          ],
          visible: true,
        },
        {
          id: "count_cigarettes",
          label: "Кількість сигарет",
          elType: "input",
          placeholder: "",
          model: "",
          type: "number",
          visible: false,
        },
      ],
    };
  },
  watch: {
    file(val) {
      if (val && val.length) {
        this.fileAttached = true;
      }
    },
    formInit: {
      deep: true,
      handler(val) {
        if (val) {
          for (let key in val) {
            if (val[key].id === "smoke" && val[key].model.id === "cigarettes") {
              for (let i in this.formInit) {
                if (this.formInit[i].id === "count_cigarettes") {
                  this.formInit[i].visible = true;
                }
              }
            } else if (
              val[key].id === "smoke" &&
              val[key].model.id !== "cigarettes"
            ) {
              for (let i in this.formInit) {
                if (this.formInit[i].id === "count_cigarettes") {
                  this.formInit[i].visible = false;
                }
              }
            }
          }
        }
      },
    },
  },
  methods: {
    ...mapMutations(["setCurrentPatient"]),
    ...mapActions(["createPatient", "loadFile"]),
    calcActualInfo() {
      let arr = [];
      this.formInit.map((el) => {
        if (el.visible) {
          arr.push(el);
        }
      });
      return arr;
    },
    createNewPatient() {
      let patientInfo = { fio: {} };
      if (this.file.length) {
        let formData = new FormData();
        formData.append("file", this.file[0].file);
        this.loadFile(formData).then((resp) => {
          patientInfo.document_id = resp.data._id;
          this.formInit.map((el) => {
            if (
              el.id === "first_name" ||
              el.id == "last_name" ||
              el.id === "middle_name"
            ) {
              if (el.model.trim()) {
                patientInfo.fio[el.id] = el.model;
              } else {
                this.error = "Не всі поля заповнені";
                setTimeout(() => {
                  this.error = null;
                }, 2000);
                return;
              }
            } else if (el.id === "phone_number") {
              if (el.model) {
                patientInfo[el.id] = el.model.replace(/\D/g, "");
              }
            } else if (el.id === "email") {
              if (el.model.trim()) {
                patientInfo[el.id] = el.model;
              } else {
                if (!patientInfo.phone_number) {
                  this.error = "Не всі поля заповнені";
                  setTimeout(() => {
                    this.error = null;
                  }, 2000);
                  return;
                }
              }
            } else if (el.elType === "select") {
              if (el.model) {
                patientInfo[el.id] = el.model.id;
              } else if (el.id === "glucose") {
                patientInfo[el.id] = parseFloat(el.model);
              } else {
                this.error = "Не всі поля заповнені";
                setTimeout(() => {
                  this.error = null;
                }, 2000);
                return;
              }
            } else {
              if (el.model && el.elType === "date") {
                patientInfo[el.id] = el.model;
              } else if (
                el.model ||
                (el.model && patientInfo.smoke === "cigarettes")
              ) {
                patientInfo[el.id] = +el.model;
              } else if (patientInfo.smoke === "cigarettes" && !el.model) {
                this.error = "Не всі поля заповнені";
                setTimeout(() => {
                  this.error = null;
                }, 2000);
                return;
              } else if (
                patientInfo.smoke !== "cigarettes" &&
                el.id === "count_cigarettes"
              ) {
                return;
              } else {
                this.error = "Не всі поля заповнені";
                setTimeout(() => {
                  this.error = null;
                }, 2000);
                return;
              }
            }
          });
          for (let k in patientInfo) {
            if (k === "smoke" && patientInfo[k] !== "cigarettes") {
              delete patientInfo.count_cigarettes;
            }
          }
          if (!this.error) {
            this.createPatient(patientInfo)
              .then((resp) => {
                if (resp) {
                  this.setCurrentPatient(resp.data);
                  this.$router.replace({ name: "CardPatient" });
                }
              })
              .catch((e) => {
                this.error = e.response.data.error_text;
                if (this.error === "token expired") {
                  this.$router.replace({ name: "Login" });
                } else {
                  setTimeout(() => {
                    this.error = null;
                    this.loginData.map((el) => (el.model = ""));
                  }, 2000);
                }
              });
          }
        });
      } else {
        this.error = "Не всі поля заповнені";
        setTimeout(() => {
          this.error = null;
        }, 2000);
      }
    },
  },
  mounted() {
    let obj = JSON.parse(localStorage.getItem("patient"));
    for (let key in obj) {
      this.formInit.map((val) => {
        if (key === val.id) {
          val.model = obj[key];
        }
      });
    }
  },
};
</script>

<style scoped lang="scss">
.client {
  &__form {
    max-width: 790px;
    padding: 0 10px;
    margin: 0 auto;
    @media (max-width: 440px) {
      padding: 0 15px;
    }

    &-wrap {
      display: grid;
      grid-template: repeat(5, 1fr) / repeat(2, 1fr);
      gap: 16px 58px;
      margin-bottom: 20px;
      @media (max-width: 767px) {
        column-gap: 30px;
      }
      @media (max-width: 440px) {
        grid-template: 1fr / 1fr;
      }
    }
    &-label {
      display: block;
      margin-bottom: 8px;
      @media (max-width: 767px) {
        margin-bottom: 5px;
      }
    }
    &-attached {
      display: block;
      margin-top: 3px;
      text-align: right;
      color: #0d6efd;
    }
    &-download {
      margin-bottom: 21px;
    }
    &-upload {
      background-color: #f8f9fa;
      border: 1px solid #adb5bd;
      box-sizing: border-box;
      border-radius: 2px;
      width: 100%;
      padding: 25px 5px 16px;
      @media (max-width: 767px) {
        padding: 18px 3px 15px;
      }
    }
    &-item {
      @media (max-width: 440px) {
        max-width: 350px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
      }
      // &:nth-child(3) {
      //   @media (max-width: 440px) {
      //     grid-row: 2/3;
      //   }
      // }
      // &:nth-child(5) {
      //   @media (max-width: 440px) {
      //     grid-row: 3/4;
      //   }
      // }
      // &:nth-child(7) {
      //   @media (max-width: 440px) {
      //     grid-row: 4/5;
      //   }
      // }
      // &:nth-child(9) {
      //   @media (max-width: 440px) {
      //     grid-row: 5/6;
      //   }
      // }
    }
    &-icon {
      margin-bottom: 10px;
      @media (max-width: 440px) {
        margin-bottom: 6px;
      }
    }
    &-text {
      display: block;
      font-size: 16px;
      margin-bottom: 6px;
      @media (max-width: 440px) {
        font-size: 14px;
        margin-bottom: 4px;
      }
    }
    &-about {
      color: #6c757d;
      display: block;
      @media (max-width: 440px) {
        font-size: 13px;
      }
    }
  }
}
</style>